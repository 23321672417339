<template>
  <div :class="stateClass" class="claim-status-tag">{{ text }}</div>
</template>

<script>
import {getStatusType} from '@/constants/esb_claim_statuses';

export default {
  props: {
    status: String,
    type: String,
  },
  computed: {
    text() {
      const {status, type} = this;

      switch (status) {
        case 'INITIATED':
          if (type.includes('BUYOUT')) {
            return 'NEW'; // workaround for buyout
          } else {
            return 'ACCEPTED'; // workaround for claims for which payment has failed
          }
        case 'CAN':
          return 'CANCELLED'; // workaround for esb status after CANCEL_SERVIFY_CLAIM
        case 'NEW':
          return 'ACCEPTED'; // workaround for old claims
        case 'New':
          return 'ACCEPTED'; // workaround for re-attend
        case 'PAYMENT SUCCESSFUL':
          return 'ACCEPTED';
        case 'ACC':
          return 'ACCEPTED';
        case 'CMP':
          return 'COMPLETED';
        default:
          return this.status?.toUpperCase() || '';
      }
    },
    stateClass() {
      const classes = [];
      const type = getStatusType(this.status);
      if (type) {
        classes.push(`claim-status-tag--${type}`);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-status-tag {
  position: relative;
  display: inline-block;
  padding: 10px 12px 10px 27px;
  font-weight: 900;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100px;
  &::before {
    position: absolute;
    top: 50%;
    left: 14px;
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transform: translateY(-50%);
    content: '';
  }
  &--IN_PROGRESS {
    color: #927647;
    background-color: #ffefd5;
    &::before {
      background-color: #927647;
    }
  }
  &--FULLFILLED {
    color: $darkGreen;
    background-color: #d8ecda;
    &::before {
      background-color: $darkGreen;
    }
  }
  &--CANCELLED {
    color: $darkRed;
    background-color: #fee3e2;
    &::before {
      background-color: $darkRed;
    }
  }
}
</style>
