<template>
  <table class="service-history__list">
    <tr class="service-history__row service-history__row--header">
      <th @click="sort('title')" class="service-history__header">
        Service Device
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'title' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'title' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th @click="sort('sn')" class="service-history__header">
        Job Number
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'sn' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'sn' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th @click="sort('addDate')" class="service-history__header">
        Opened Date
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'addDate' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'addDate' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th @click="sort('number')" class="service-history__header">
        Contract Number
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'number' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'number' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th @click="sort('type')" class="service-history__header">
        Service Type
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'type' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'type' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th @click="sort('status')" class="service-history__header">
        Status
        <span
          class="service-history__sort"
          :class="[
            {
              'service-history__sort--down': sortBy === 'status' && sortDir === 'asc',
              'service-history__sort--up': sortBy === 'status' && sortDir === 'desc',
            },
          ]"
        />
      </th>
      <th class="service-history__header"></th>
    </tr>
    <tr v-for="historyItem in sortedHistory" :key="historyItem.id" class="service-history__row">
      <td data-title="Service device" class="service-history__cell">
        {{ historyItem.title || 'N/A' }}
      </td>
      <td data-title="Job Number" class="service-history__cell">{{ historyItem.sn || 'N/A' }}</td>
      <td data-title="Opened Date" class="service-history__cell">
        {{ historyItem.addDate || 'N/A' }}
      </td>
      <td data-title="Contract Number" class="service-history__cell">
        {{ historyItem.number || 'N/A' }}
      </td>
      <td :data-title="historyItem.type ? 'Claim Type' : ''" class="service-history__cell">
        {{ historyItem.type || 'N/A' }}
      </td>
      <td class="service-history__cell">
        <ClaimStatusTag
          :status="historyItem.status"
          :type="historyItem.claimType"
          class="device-claim__status-tag"
        />
      </td>
      <td class="service-history__cell">
        <router-link
          :to="getClaimLink(historyItem.sn)"
          class="hs-link service-history__details-link"
          >Details</router-link
        >
      </td>
    </tr>
  </table>
</template>

<script>
import {mapGetters} from 'vuex';
import ClaimStatusTag from '@/components/shared/ClaimStatusTag';

export default {
  components: {
    ClaimStatusTag,
  },
  data() {
    return {
      sortBy: 'title',
      sortDir: 'asc',
    };
  },
  computed: {
    ...mapGetters('requestService', ['serviceHistory']),
    sortedHistory() {
      if (!this.serviceHistory) {
        return;
      }

      const sorted = Array.from(this.serviceHistory);
      return sorted.sort((a, b) => {
        let modifier = 1;
        const sortIndex = this.sortBy;

        if (this.sortDir === 'desc') {
          modifier = -1;
        }

        if (sortIndex === 'addDate') {
          return (new Date(a[sortIndex]) - new Date(b[sortIndex])) * -modifier;
        } else {
          if (a[sortIndex] < b[sortIndex]) {
            return -modifier;
          }

          if (a[sortIndex] > b[sortIndex]) {
            return modifier;
          }

          return 0;
        }
      });
    },
  },
  methods: {
    getClaimLink(id) {
      const {query} = this.$route;
      return {name: 'ShowJob', params: {id}, query};
    },
    sort(keyParam) {
      if (keyParam === this.sortBy) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = keyParam;
    },
  },
};
</script>

<style scoped lang="scss">
.service-history {
  &__list {
    display: block;
    border-top: 1px solid #eceae6;

    @include mf($gridMDBreakpoint) {
      display: table;
      width: 100%;
      margin-top: 20px;
      padding: 0;
      border-top-color: #d1d1d1;
      border-collapse: collapse;
    }
  }
  &__header,
  &__cell {
    margin: 0;
    padding: 12px 10px;
    text-align: left;
    vertical-align: middle;

    @include mf($gridMDBreakpoint) {
      padding: 15px 10px;
    }
  }
  &__header {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: table-cell;
      color: #000000;
      font: bold 13px / 100% 'Museo Sans Rounded', sans-serif;
      background: #f9f9f9;
      border-bottom: 1px solid #d1d1d1;
      cursor: pointer;
    }
  }
  &__sort {
    display: inline-block;
    width: 8px;
    height: 13px;
    margin-left: 5px;
    vertical-align: middle;
    background: url(~@/assets/images/sort.svg) no-repeat 0 0/8px 13px;
    &--up {
      background: url(~@/assets/images/sort-up.svg) no-repeat 0 0/8px 5px;
    }
    &--down {
      background: url(~@/assets/images/sort-down.svg) no-repeat 0 100%/8px 5px;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &--header {
      display: none;
    }

    padding: 5px 0;
    border-bottom: 1px solid #eceae6;

    @include mf($gridMDBreakpoint) {
      display: table-row;
      padding: 0;
      border-bottom-width: 0;
    }
  }
  &__cell {
    flex: 1 0 50%;
    align-self: flex-end;
    max-width: 50%;
    color: #757575;
    font: 14px / 100% 'Museo Sans Rounded', sans-serif;
    &:nth-child(6) {
      // Status
      flex-basis: 100%;
      order: 1;
      max-width: 100%;
    }
    &:nth-child(1) {
      // Device
      order: 2;
    }
    &:nth-child(2) {
      // Date
      order: 4;
    }
    &:nth-child(3) {
      // Job ID
      order: 3;
    }
    &:nth-child(4) {
      // Contract ID
      order: 5;
    }
    &:nth-child(5) {
      // Type
      order: 6;
    }
    &:nth-child(7) {
      // Details
      order: 7;
    }
    &::before {
      display: block;
      margin-bottom: 5px;
      color: #000000;
      font: bold 13px / 100% 'Museo Sans Rounded', sans-serif;
      content: attr(data-title);
    }

    @include mf($gridMDBreakpoint) {
      flex: inherit;
      max-width: inherit;
      border: solid #eceae6;
      border-width: 0 0 1px;
      &::before {
        display: none;
      }
    }
  }
  &__details-link {
    display: flex;
    align-items: center;
    order: 3;
    font-size: 14px;

    @include mf($gridSMBreakpoint) {
      font-size: 16px;
    }
    &::after {
      display: block;
      width: 9px;
      height: 6px;
      margin-left: 3px;
      background: url(~@/assets/images/nav-back.svg) no-repeat 0 0/9px 6px;
      transform: rotate(180deg);
      content: '';
    }
  }
}
</style>
