export const STATUSES = {
  IN_PROGRESS: ['NEW', 'ACCEPTED', 'ACC', 'PAYMENT SUCCESSFUL'],
  FULLFILLED: ['COMPLETED', 'CMP'],
  CANCELLED: ['CLOSED', 'CANCELLED', 'CAN', 'DENIED'],
};

export const STATUSES_INDEX = {};
for (const [groupName, statuses] of Object.entries(STATUSES)) {
  for (const status of statuses) {
    STATUSES_INDEX[status] = groupName;
  }
}

export function getStatusType(status) {
  return STATUSES_INDEX[status?.toUpperCase()];
}

export function isInProgressStatus(status) {
  return getStatusType(status) === 'IN_PROGRESS';
}

export function isCompletedStatus(status) {
  return !isInProgressStatus(status);
}

export function isFullfilledStatus(status) {
  return getStatusType(status) === 'FULLFILLED';
}

export function isCancelledStatus(status) {
  return getStatusType(status) === 'CANCELLED';
}

// TODO: This is a workaround for making Request service button disabled.
export function isNotEligibleForRepairStatus(serviceJobSubStatusName) {
  return serviceJobSubStatusName?.toLowerCase() === 'device not eligible for repair';
}
