var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"service-history__list"},[_c('tr',{staticClass:"service-history__row service-history__row--header"},[_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('title')}}},[_vm._v(" Service Device "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'title' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'title' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('sn')}}},[_vm._v(" Job Number "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'sn' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'sn' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('addDate')}}},[_vm._v(" Opened Date "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'addDate' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'addDate' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('number')}}},[_vm._v(" Contract Number "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'number' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'number' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('type')}}},[_vm._v(" Service Type "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'type' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'type' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header",on:{"click":function($event){return _vm.sort('status')}}},[_vm._v(" Status "),_c('span',{staticClass:"service-history__sort",class:[
          {
            'service-history__sort--down': _vm.sortBy === 'status' && _vm.sortDir === 'asc',
            'service-history__sort--up': _vm.sortBy === 'status' && _vm.sortDir === 'desc',
          } ]})]),_c('th',{staticClass:"service-history__header"})]),_vm._l((_vm.sortedHistory),function(historyItem){return _c('tr',{key:historyItem.id,staticClass:"service-history__row"},[_c('td',{staticClass:"service-history__cell",attrs:{"data-title":"Service device"}},[_vm._v(" "+_vm._s(historyItem.title || 'N/A')+" ")]),_c('td',{staticClass:"service-history__cell",attrs:{"data-title":"Job Number"}},[_vm._v(_vm._s(historyItem.sn || 'N/A'))]),_c('td',{staticClass:"service-history__cell",attrs:{"data-title":"Opened Date"}},[_vm._v(" "+_vm._s(historyItem.addDate || 'N/A')+" ")]),_c('td',{staticClass:"service-history__cell",attrs:{"data-title":"Contract Number"}},[_vm._v(" "+_vm._s(historyItem.number || 'N/A')+" ")]),_c('td',{staticClass:"service-history__cell",attrs:{"data-title":historyItem.type ? 'Claim Type' : ''}},[_vm._v(" "+_vm._s(historyItem.type || 'N/A')+" ")]),_c('td',{staticClass:"service-history__cell"},[_c('ClaimStatusTag',{staticClass:"device-claim__status-tag",attrs:{"status":historyItem.status,"type":historyItem.claimType}})],1),_c('td',{staticClass:"service-history__cell"},[_c('router-link',{staticClass:"hs-link service-history__details-link",attrs:{"to":_vm.getClaimLink(historyItem.sn)}},[_vm._v("Details")])],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }