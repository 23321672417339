<template>
  <wrapper-with-back class="service-history">
    <div class="service-history__heading-wrapper">
      <hs-heading title="Service History" class="service-history__heading" />
    </div>
    <template v-if="loadingInProgress">
      <div class="service-history__loader-cont">
        <i class="service-history__loader"></i>
      </div>
    </template>
    <template v-else>
      <service-history-list v-if="isThereAnyClaim" />
      <div v-else class="service-history__no-claims">
        <div class="service-history__no-claims-text">
          <h2 class="service-history__no-claims-title">
            You haven't request service yet, no service history.
          </h2>
        </div>
      </div>
    </template>
  </wrapper-with-back>
</template>

<script>
import {mapState} from 'vuex';
import ServiceHistoryList from '@/components/service-history/List.vue';

export default {
  components: {
    ServiceHistoryList,
  },
  data() {
    return {
      loadingInProgress: true,
    };
  },
  computed: {
    ...mapState('jobInfo', ['claimsList']),
    isThereAnyClaim() {
      return this.claimsList?.length;
    },
  },
  methods: {
    getHistory() {
      const {customerRef} = this.$route.query;
      this.$store
        .dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        })
        .then((resp) => {
          Promise.all([
            this.$store.dispatch('myDevices/GET_MY_DEVICES', {
              customerRef: resp?.customerRef,
            }),
            this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
              customerRef: resp?.customerRef,
            }),
          ]).finally(() => {
            this.loadingInProgress = false;
          });
        });
    },
  },
  created() {
    this.getHistory();
  },
};
</script>

<style scoped lang="scss">
.service-history {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }
  &__heading-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mf($gridMDBreakpoint) {
      flex-wrap: nowrap;
    }
  }
  &__heading {
    flex-grow: 1;
    margin-top: 20px;

    @include mf($gridMDBreakpoint) {
      flex-grow: 0;
    }
  }
  &__loader-cont {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  &__loader {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background-image: url('~@/assets/images/hs-loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
  }
  &__no-claims {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px dashed $lightGray;
    border-radius: 4px;
  }
  &__no-claims-text {
    display: block;
    width: 100%;
    max-width: 521px;
    margin: 35px 0 16px;
    font-size: 15px;
    text-align: center;

    @include mf($gridMDBreakpoint) {
      font-size: 18px;
    }
  }
  &__no-claims-title {
    margin: 0;
    font-weight: 700;
    font-size: 18px;

    @include mf($gridMDBreakpoint) {
      font-size: 20px;
    }
  }
}
</style>
